export default [
    {
        name: 'Figma',
        description: 'Figma is a web-based design tool that is used to create user interfaces. It is commonly used to design websites, mobile applications, and other digital products.',
        notes: []
    },
    {
        name: 'Adobe XD',
        description: 'Adobe XD is a design tool that is used to create user interfaces. It is commonly used to design websites, mobile applications, and other digital products.',
        notes: []
    },
    {
        name: 'Adobe Photoshop',
        description: 'Photoshop is a raster graphics editor that is used to create and edit images. It is commonly used to design websites, mobile applications, and other digital products.',
        notes: []
    },
    {
        name: 'Adobe Illustrator',
        description: 'Illustrator is a vector graphics editor that is used to create and edit images. It is commonly used to design logos, icons, and other digital products.',
        notes: []
    },
    {
        name: 'Adobe Lightroom',
        description: 'Lightroom is a photo editing tool that is used to edit and organize images. It is commonly used by photographers to enhance their photos.',
        notes: []
    },
    {
        name: 'Adobe Premiere Pro',
        description: 'Premiere Pro is a video editing tool that is used to edit and produce videos. It is commonly used by videographers to create professional videos.',
        notes: []
    },
    {
        name: 'Adobe InDesign',
        description: 'InDesign is a desktop publishing tool that is used to create and publish documents. It is commonly used to design brochures, flyers, and other printed materials.',
        notes: []
    }
]