import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Components
import BgSection from '../components/BgSection'
import Intro from '../components/Intro'
import WorkTogether from '../components/WorkTogether'
import Card from '../components/Card'

// Assets
import aboutMeImage from '../assets/images/about-me-image.jpg'

export default function Contact() {
    const contactPossibilities = [
        { platform: 'LinkedIn', userName: '@meesakveld', url: 'https://www.linkedin.com/in/meesakveld/' },
        { platform: 'Instagram', userName: '@meesakveld', url: 'https://www.instagram.com/meesakveld/' },
        { platform: 'Instagram (Photography)', userName: '@paridaeproductions', url: 'https://www.instagram.com/paridaeproductions/' },
        { platform: 'GitHub', userName: '@meesakveld', url: 'https://www.github.com/meesakveld/' },
        { platform: 'Email', userName: 'meesakveld@gmail.com', url: 'mailto:meesakveld@gmail.com' },
    ]

    return (
        <>
            <Helmet>
                <title>Contact | Mees Akveld</title>
                <meta name="twitter:title" content="Contact | Mees Akveld" />
				<meta property="og:title" content="Contact | Mees Akveld" />
            </Helmet>

            <div className='flex flex-col gap-0'>
                <BgSection className="pt-24 md:pt-24 flex flex-col gap-24 md:gap-40 z-10">

                    <Intro subtitle="I know you're here to:" titlePrt1="Keep in touch" titlePrt2="With me" />

                    <h1 className='hidden'>Contact</h1>

                    <Card>
                        <div className='flex flex-col gap-16 sm:flex-row sm:items-center'>
                            <div className='sm:w-1/2'>
                                <img src={aboutMeImage} alt="Mees Akveld" className='rounded-[20px]' />
                            </div>

                            <div className='flex flex-col gap-8'>

                                <div className='text-center flex flex-col gap-6'>
                                    <h2 className='uppercase font-passionOne text-8vw sm:text-6vw'>Get in touch</h2>
                                    <p>Feel free to reach me if you have a project, idea, suggestion or just to chat.</p>
                                </div>

                                <div className='flex gap-8 m-auto mb-12'>
                                    <div className='flex flex-col gap-4 w-1/2'>
                                        {contactPossibilities.map((contact, index) => (
                                            <h3 key={index} className='text-[0.8rem] text-right w-auto'>{contact.platform}</h3>
                                        ))}
                                    </div>
                                    <div className='flex flex-col gap-4 items-baseline w-1/2'>
                                        {contactPossibilities.map((contact, index) => (
                                            <Link key={index} to={contact.url} className='text-blue-500 lined' target="_blank" rel="noreferrer">{contact.userName}</Link>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Card>

                    <WorkTogether />

                </BgSection>

            </div>

        </>
    )
}
