import React from 'react'
import Button from './Button'

import macbook from '../assets/icons/macbook.svg'
import phone from '../assets/icons/phone.svg'
import { Link } from 'react-router-dom'


/**
 * Renders a showcase card component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the project.
 * @param {string} props.image - The image source URL.
 * @param {string} props.imageAlt - The image alternative text.
 * @param {string} props.platform - The platform of the project ('web' or 'mobile').
 * @param {string} props.url - The URL of the project.
 * @returns {JSX.Element} The rendered showcase card component.
 */
export default function ShowcaseCard({ title, image, imageAlt, platform, url }) {

    // Check if all required props are provided.
    let missingItems = []
    if (!title) missingItems.push('title')
    if (!image) missingItems.push('image')
    if (!imageAlt) missingItems.push('imageAlt')
    if (!url) missingItems.push('url')
    if (!platform || (platform !== 'web' && platform !== 'mobile')) missingItems.push('platform')
    if (!title || !image || !platform || !url) return (<div className='text-red-500'><b>Error</b>: Rendering not possible, not all required props are provided or correct. The following props are missing or incorrect: <span className="font-bold">{missingItems.join(', ')}</span></div>)

    // Determine the device icon based on the platform.
    let device = null
    if (platform === 'web') device = macbook
    if (platform === 'mobile') device = phone

    return (
        <Link to={url}>
            <div className='relative' title={title}>
                <img className="w-full min-[415px]:indent-bottom rounded-[30px] object-cover aspect-video" src={image} alt={imageAlt} />
                <div className='absolute bottom-0 right-0 flex items-center gap-3 w-72 max-[415px]:hidden'>
                    {device &&
                        <div className='w-10 h-10 flex-shrink-0 bg-white p-2 rounded-full aspect-square'>
                            <img className='w-full h-full' src={device} alt={platform} />
                        </div>
                    }
                    <Button title={title} bgColor="white" url={url} />
                </div>
            </div>
        </Link>
    )
}
