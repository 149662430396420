import card from '../../assets/images/projects/judoclub-goshin/card.png';
import banner from '../../assets/images/projects/judoclub-goshin/banner.png';
import showcaseWide1 from '../../assets/images/projects/judoclub-goshin/showcase-wide-1.png';
import showcaseWide2 from '../../assets/images/projects/judoclub-goshin/showcase-wide-2.png';
import showcaseWide3 from '../../assets/images/projects/judoclub-goshin/showcase-wide-3.png';
import devider from '../../assets/images/projects/judoclub-goshin/devider.png';
import mobile1 from '../../assets/images/projects/judoclub-goshin/mobile-1.png';
import mobile2 from '../../assets/images/projects/judoclub-goshin/mobile-2.png';
import mobile3 from '../../assets/images/projects/judoclub-goshin/mobile-3.png';

export default {
    title: "Judoclub Goshin",
    slug: 'judoclub-goshin',
    platform: 'web', // 'web' or 'mobile'
    githubUrl: null,
    mobile: {
        appStoreUrl: null,
    },
    web: {
        url: "https://goshin-web.vercel.app/",
    },
    description: [
        `Judoclub Goshin is a judo club located in Ghent, Belgium. The club offers judo classes for all ages and levels, from beginners to advanced judokas.`,
        `I developed a new website for Judoclub Goshin, using <strong>Remix.run</strong> as the framework and <strong>Strapi</strong> as the headless CMS. The website is fully content driven by using <strong>dynamic zones</strong> in Strapi, allowing the client to easily manage and update the content on the website. The website is also fully responsive and optimized for all devices, ensuring a great user experience for all visitors.`,
        `For specific things like trainers, members of the board, news articles, and more, I created custom tables in Strapi to manage the data in a structured way. Pages like techniques and news articles are provided with a <strong>search and filter functionality</strong> and <strong>endless scrolling</strong> to easily navigate through the content.`,
        `The website is optimized for search engines by <strong>implementing SEO best practices</strong>, ensuring it is easily discoverable by search engines. All SEO settings can be customized directly from the <strong>Strapi</strong> backend.`,
        `The website has a <strong>light mode</strong> and <strong>dark mode</strong> to provide a great user experience for all visitors. The user's preference is stored in the local storage and is automatically applied when revisiting the website.`,
    ],
    developmentNotes: [
        { title: "Remix.run", text: "Used Remix.run as the framework for the website, providing a great developer experience and performance out of the box." },
        { title: "Strapi", text: "Used Strapi as the headless CMS to manage the content on the website. The backend is fully customized with custom colors and start screen." },
        { title: "Dynamic zones", text: "Used dynamic zones in Strapi to create a flexible content structure, allowing the client to easily manage and update the content on the website." },
        { title: "SEO", text: "Implemented SEO best practices to ensure the website is optimized for search engines." },
    ],
    mostProudOf: [
        // Design, learned fully how to use Strapi, first time using Remix.run
        `I'm most proud of the design of the website, which is clean, modern, and fully responsive. Besides the design, I also learned a lot about Strapi and how to create a fully customized backend for a website.`,
        `By using dynamic zones in Strapi, I created a flexible content structure that allows the client to easily manage and update the content on the website. This makes the website future-proof and ensures that the client can easily add new content in the future.`,
        `Finally, this was my first time using Remix.run as the framework for a website. I was impressed by the developer experience and performance out of the box, and I'm looking forward to using it for future projects.`,
    ],
    images: {
        card: { src: card, alt: `Card` },
        banner: { src: banner, alt: `Banner` },
        showcaseWide: [
            { src: showcaseWide1, alt: `Showcase wide 1` },
            { src: showcaseWide2, alt: `Showcase wide 2` },
            { src: showcaseWide3, alt: `Showcase wide 3`, },
        ],
        devider: { src: devider, alt: `Devider` },
        mobileView: [
            { src: mobile1, alt: `Mobile 1` },
            { src: mobile2, alt: `Mobile 2` },
            { src: mobile3, alt: `Mobile 3` },
        ]
    }
}