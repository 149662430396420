import React from 'react'
import { Helmet } from 'react-helmet'

// Components
import BgSection from '../components/BgSection'
import Intro from '../components/Intro'
import Title from '../components/Title'
import Card from '../components/Card'
import ShowcaseCard from '../components/ShowcaseCard'
import WorkTogether from '../components/WorkTogether'

// Assets
import mountain from '../assets/images/mountain.svg'

// Data
import projects from '../data/projects/'

// Functions
import getAlignment from '../functions/getAlignment'

export default function Projects() {
    return (
        <>
            <Helmet>
                <title>Projects | Mees Akveld</title>
                <meta name="twitter:title" content="Projects | Mees Akveld" />
                <meta property="og:title" content="Projects | Mees Akveld" />
            </Helmet>

            <div className='flex flex-col gap-0'>
                <BgSection className="pt-24 md:pt-24 flex flex-col gap-24 md:gap-40 z-10">

                    <Intro subtitle="Let's see some:" titlePrt1="Things that" titlePrt2="I've been up to" />

                    <Title title='Portfolio' titlePrt1='Port' titlePrt2='Folio' titleH='h1' imgPath={mountain} imgClassName="h-[50vw]" />

                    <div className='flex flex-col gap-4'>
                        <Card title="What i've been doing" description="Since junuari 2023, I've found my passion for developing digital projects. First I started with Mobile Development and after loving that so much, I started in september 2023 at Artevelde University of Applied Sciences to become a Full-Stack Developer." >
                            <div className='flex flex-col gap-4 sm:flex-row sm:gap-0 sm:justify-between mt-8 sm:mb-8'>
                                <h3 className='text-[4vw] font-[400] leading-[100%]' style={{ fontSize: 'clamp(1vw, 4vw, 2rem)' }}>Projects</h3>
                                <div className='flex flex-col gap-12 sm:w-1/2'>
                                    <div className='flex flex-col gap-4'>
                                        <p>Today, I work with the latest technologies to create innovative and user-friendly solutions. I am passionate about what I do and always strive to deliver the best possible results.</p>
                                        <p>Being honest is very important to me, and I uphold this value in every situation. I am very eager to learn and enjoy discovering new things every day.</p>
                                    </div>
                                </div>
                            </div>
                        </Card>

                        <div className='flex flex-col sm:grid md:grid-cols-2 gap-4'>
                            {projects.map((project, index) => (
                                <ShowcaseCard key={`project-${index}`} title={project.title} image={project.images.card.src} imageAlt={project.images.card.src} url={`/projects/${project.slug}`} platform={project.platform} />
                            ))}
                        </div>

                    </div>

                    <WorkTogether />

                </BgSection>
            </div>

        </>
    )
}
